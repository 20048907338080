@font-face {
  font-family: Rounded Elegance;
  src: url(./font/RoundedElegance.woff);
}

.bannerBackground {
  position: fixed;
  top: 0;
  left: 0;

  /* Preserve aspect ratio */
  min-width: 100%;
  min-height: 100%;
}

html {
  background-color: #e1e2e1;
}

body {
  background-color: #e1e2e1;
}

.container-fluid {
  padding: 0;
}

.NavCollapsible > .collapsible-header {
  padding-left: 32px;
}

.NavCollapsible > .collapsible-header > i {
  width: 24px;
  margin-right: 32px;
  color: rgba(0, 0, 0, 0.54);
}

h4 {
  font-size: 20px;
  padding-top: 24px;
  padding-left: 24px;
  padding-bottom: 24px;
  font-weight: 600;
  margin: 0;
}

h5 {
  font-size: 16px;
}

.forgot-password-header {
  font-weight: 501;
  font-size: 20px;
}

.forgot-password-description {
  padding-top: 21px;
  padding-bottom: 32px;
}

.loginLine {
  float: left;
  width: 60px;
  height: 2px;
  background-color: rgba(0, 0, 0, 0.38);
  transition: background-color 0.5s ease-in;
}

.loginLine.is-active {
  background-color: #1897ec;
}

.registerLine {
  float: right;
  width: 60px;
  height: 2px;
  background-color: rgba(0, 0, 0, 0.38);
  transition: background-color 0.5s ease-in;
}

.registerLine.is-active {
  background-color: #1897ec;
}

.authMargin {
  margin-top: calc(100vh / 6);
}

.form-control {
  border: 1px solid hsl(0, 0%, 70%);
}

.form-control:disabled {
  background-color: #dddddd;
}

[type='checkbox'].filled-in:disabled:not(:checked) + span:not(.lever):after {
  background-color: #dddddd;
}

.changePasswordModal {
  width: 433px;
}

.changePasswordModal > .modal-content {
  padding: 0;
  border-radius: 0;
}

.admin-modal > .modal-content {
  padding: 0;
  border-radius: 0;
}

.modalPadding {
  padding: 12px 24px 24px 24px;
}

.material-icons {
  color: rgb(0, 0, 0, 0.54);
}

.App {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal !important;
  line-height: normal;
  font-size: 14px !important;
  color: rgba(0, 0, 0, 0.87);
}

.modal-fix.modal {
  bottom: inherit !important;
}

.admin-modal.modal .row {
  margin-left: inherit;
  margin-right: inherit;
  margin-bottom: 5px;
}

.light-grey {
  background-color: #f5f5f6 !important;
}

.dark-grey {
  background-color: #30363d !important;
}

.spinner-blue,
.spinner-blue-only {
  border-color: #1897ec;
}

.switch label input[type='checkbox']:checked + .lever {
  background-color: #4bcaff;
}

.switch label input[type='checkbox']:checked + .lever:after {
  background-color: #1897ec;
}

.tableCard > .card-content {
  padding: 0px 0px 24px 0px;
}

.aeon-logo {
  font-family: Rounded Elegance;
  font-size: 24px;
  padding: 0 0 0 20px;
  font-weight: 600;
  /* margin-bottom: 60px; */
  /* line-height: 30px; */
  color: rgba(0, 0, 0);
}

a:hover {
  text-decoration: none !important;
  color: #343434;
}

li > button.btn-flat {
  font-family: 'Roboto', sans-serif !important;
  font-size: 16px;
  display: block;
  line-height: 22px;
  padding: 14px 16px;
  text-transform: none;
  font-weight: 400 !important;
  width: 100%;
  height: 100%;
  text-align: left;
}

.timesheet-container {
  padding-left: 47px;
  padding-right: 47px;
  padding-top: 24px;
}

.blue-text {
  color: #1897ec !important;
}

.admin-container {
  padding-left: 88px;
  padding-right: 88px;
}

td {
  padding: 0 !important;
}

/* input[type="text"]:not(.browser-default):not(.goalModalInputFields > input) { */
input[type='text']:not(.browser-default) {
  height: inherit !important;
}

.tableHeaderBar {
  -webkit-box-shadow: none;
  box-shadow: none;
  margin: 1px 0 1px 0;
  padding: 12px;
}

.invalid-feedback {
  color: #dc3545;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  display: inherit;
}

.invalid-feedback-border {
  border-color: #dc3545;
}

.adminHeaderBar {
  -webkit-box-shadow: none;
  box-shadow: none;
  margin: 1px 0 1px 0;
  padding: 12px 12px 12px 19px;
  background: #f5f5f6;
}

.forgot-password {
  text-transform: none;
}

[type='checkbox'] + span:not(.lever) {
  margin-top: 10px;
  padding-top: 10px;
  padding-left: 20px;
}

[type='checkbox'].filled-in:checked + span:not(.lever):after {
  border: 2px solid #1897ec;
  background-color: #1897ec;
}

[type='checkbox'].filled-in:not(:checked) + span:not(.lever):after {
  border: 1px solid #6b757f !important;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.form-control:focus {
  border-color: #1897ec;
  box-shadow: 0 0 0 0.05rem #1897ec;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.invalid-cell {
  border-color: #dc3545;
}

.invalid-text {
  color: #dc3545;
}

table td {
  padding: 0 0.2rem 0 0.2rem !important;
}

table th {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.54);
}

tr {
  vertical-align: top !important;
}

tbody > tr > td {
  vertical-align: top !important;
}

tbody > tr > td .form-control {
  line-height: 25px !important;
}

button {
  font-weight: 500 !important;
}

.fa-circle {
  color: #1897ec;
}

.card.cardSmallBar .card-content {
  padding: 8px 47px 8px 47px !important;
}

.card.cardSmallBar {
  margin: 0;
}

.card.cardGoal .card-content {
  padding: 5px 0px !important;
  height: 150px;
  max-height: 150px;
}

.cardGoalHeader {
  height: 50px;
  margin-bottom: 0px !important;
}

.cardGoalDropDown {
  color: rgba(0, 0, 0, 0.54);
  width: 36px;
  height: 36px;
  border-radius: 50%;
}

.cardGoalDropDown:hover {
  background-color: #f5f5f5 !important;
}

.cardGoalDropDown:focus {
  background-color: transparent;
}

.cardGoalHeader > div > ul.dropdown-content {
  width: 150px !important;
  height: 100px !important;
}

.cardGoalIcon {
  width: 36px;
  height: 36px;
  text-align: center;
  font-size: 1.6rem !important;
}

.cardGoalText {
  font-family: 'Roboto', 'sans-serif' !important;
  font-weight: 600 !important;
  color: rgba(12, 13, 13, 0.54) !important;
  font-style: normal;
  font-size: 14px !important;
  padding: 0px 20px !important;
}

.cardGoalDescription {
  font-family: 'Roboto', 'sans-serif' !important;
  font-weight: 400 !important;
  color: rgba(12, 13, 13, 0.54) !important;
  font-style: normal;
  font-size: 14px !important;
  max-height: 140px;
  overflow-y: hidden;
}

.addGoalText {
  font-size: 16px;
  color: #1897ec;
}

.editGoalText {
  font-size: 14px !important;
  color: rgba(0, 0, 0, 0.87);
}

.editGoalModal {
  font-family: 'Roboto', 'sans-serif' !important;
  height: 550px !important;
  border-radius: 0px !important;
  overflow-y: hidden !important;
}

.editGoalModal > .modal-content {
  padding: 0;
  border: none;
  border-radius: 0;
  overflow-y: visible !important;
}

.goalModalDescription {
  min-height: 58px;
}

.goalRow {
  max-height: 150px;
}

.removePadding > .card-content {
  padding: 0;
}

.fullLink {
  width: 100%;
  text-align: left;
}

.fullLink:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.removeRowMargin {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}

.editGoalDropDown {
  width: 350px;
}

.input-field > label {
  left: 1rem !important;
}

.input[type='text']:not(.browser-default):focus:not([readonly]) + label {
  color: #9e9e9e !important;
}

/* .editGoalModal > .modal-content > .input-field {
  position: relative !important;
  margin: 0 !important;
}

.editGoalModal > .modal-content > .input-field > input {
  height: 3rem !important;
}

.editGoalModal > .modal-content > .row > .col > .input-field {
  position: relative !important;
  margin: 0 !important;
}

.editGoalModal > .modal-content > .row > .col > .input-field > input {
  height: 3rem !important;
} */

table.highlight > tbody > tr:hover > td > span.showOnHover {
  display: block !important;
}

.adminTable > tbody > tr:not(.searchRow) {
  line-height: 46px;
}

.adminTable.highlight > tbody > tr:hover > td > div > button.showOnHover {
  display: inline !important;
}

.adminAddButton {
  position: relative;
  top: 100px;
  left: -30px;
}

.col {
  max-width: initial;
  flex-grow: initial;
  flex-basis: initial;
  position: initial;
}

span.removeCheckMargin > label {
  margin: 0 !important;
}

.successfully-saved.hide-opacity {
  opacity: 0;
}

.successfully-saved {
  transition: opacity 3s ease-in-out;
  -webkit-transition: opacity 3s ease-in-out;
  -moz-transition: opacity 3s ease-in-out;
  -ms-transition: opacity 3s ease-in-out;
  -o-transition: opacity 3s ease-in-out;
  opacity: 1;
}

.msft-button {
  cursor: pointer;
}
